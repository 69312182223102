import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CFormSelect,
	CButton,
	CImage,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CSpinner,
	CLink,
	CDropdown,CDropdownItem,CDropdownToggle,CDropdownMenu,
	CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyDialogform,
	MyPagination,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc,cAngkaTgl,cBulanPendek,cBulanPanjang } from '../helpers/functions'

const pjson 		= require('../../package.json')
const Laplabarugi	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 25) : (uSettingObj.jml_laporan || 100);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	
	const uHeaderActionObj  		= useSelector(state => state.gInitHeaderAction);
	const uHandelView 				= uHeaderActionObj.isHeaderView || false;
	const uHandelReset 				= uHeaderActionObj.isHeaderreset || false;

	//--VIEWS_VARS--/
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const uIsHapusallow					= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uIsDataloaded,setDataloaded]	= React.useState(false); 
	const [uCaptionObj,setCaptionObj]	= React.useState({}); 
	const [uFilterObj,setFilterObj]		= React.useState({});
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uSortedObj,setSortedObj]		= React.useState({});
	//const uFilterObj		  		= useSelector(state => state.gFilterObj) || {};

	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);

	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uDatadefaultArr,setDatadefaultArr]= React.useState([]);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uDatasumArr,setDatasumObj]	= React.useState({});
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	//--END VIEWS_VARS--*/

	//--FORM_VARS--/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uHeaderDlgform,setHeaderDlgform]	= React.useState("");
	const [uFormdataObj,setFormdataObj]		= React.useState({});
	//--END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData					= document.getElementById("idjmldata");
	const [uElBtnsimpan,setElBtnsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	//--END DOM_VARS--/

	//--SETTING_CETAK--/
	const uSettingcetakObj		= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uHTMLstylelaporan		= useSelector(state => state.gHTMLstyleLaporan);
	const uFrameExport 			= document.getElementById("ifmcontentstoprint");
	//--END SETTING_CETAK--/

	//--HANDEL--/
	const hdlToggleDlgform = () => { setDlgformshow(false) }
	const hdlKlikDlgsimpan = () => {
		//--VALIDASI_FILTER--/
		let vElfocus	= document.getElementById("inpcabangid");
		if(parseInt(uFormdataObj.cabang_id||"0") <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Cabang Harus Dipilih !");
			return
		}
		vElfocus	= document.getElementById("inpjenis");
		if((uFormdataObj.jenis||"").trim() !== "TAHUN"
			&& (uFormdataObj.jenis||"").trim() !== "BULAN") {
			vElfocus && vElfocus.focus();
			showToast("Jenis Periode Laporan Tidak Valid !");
			return
		}

		let vTanggal;
		if((uFormdataObj.jenis||"").trim() === "TAHUN") {
			vElfocus	= document.getElementById("inptahun");
			vTanggal  	= uFormdataObj.thn+"-01-01"; 
			if(!UFunc.isDateValid(vTanggal)) {
				vElfocus && vElfocus.focus();
				showToast("Periode Tahun Laporan Tidak Valid ! "); return
			}
		} else {
			vElfocus	= document.getElementById("inpbulanid");
			vTanggal  	= uFormdataObj.thn+"-"+uFormdataObj.bln+"-01"; 
			if(!UFunc.isDateValid(vTanggal)) {
				vElfocus && vElfocus.focus();
				showToast("Periode Bulan-Tahun Laporan Tidak Valid !"); return
			}
		}

		const vTanggalDT	= UFunc.toDate(vTanggal);
		if(UFunc.DateIsBeyond(vTanggalDT)) {
			vElfocus && vElfocus.focus();
			showToast("Periode Laporan Melewati Tanggal SAAT INI ! "); return
		}
		//alert(vTanggalDT);return;
		//--END VALIDASI_FILTER--/

		setDataloaded(false);
		apiLoaddata();
	}
	const hdlKlikSort = (_NAMAKOL) => {
		_NAMAKOL		= (_NAMAKOL||"").toUpperCase();
		const vNamenow	= (uSortedObj.nama||"").toUpperCase();
		const vSortnow	= (uSortedObj.jenis||"").toUpperCase();

		const vSortedObj		= uSortedObj;
		vSortedObj.init_aktif 	= "YA";
		if(vNamenow==="" || vNamenow !== _NAMAKOL) {
			vSortedObj.nama 	= _NAMAKOL.toLowerCase();
			vSortedObj.jenis 	= "ASCENDING";
		} else if(vNamenow===_NAMAKOL) {
			if(vSortnow==="ASCENDING")
				vSortedObj.jenis = "DESCENDING";
			else if(vSortnow==="DESCENDING") {
				 vSortedObj.nama 	= "";
				 vSortedObj.jenis 	= "";
			}
		}
		vSortedObj.init_aktif 	= undefined;

		setSortedObj(vSortedObj);
		setEfekview(!uEfekview);
	}
	const hdlKlikCetak = () => {
		if(uDatamainArr.length <= 0) return;

		const vContent 	= contentCetak();
		if(vContent === "") return
		//console.log("(Laplabarugi - hdlKlikCetak) vContent = "+vContent)

		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.window.focus();
		pri.window.print();
	}
	const hdlKlikExcel = () => {}

	//--END HANDEL--/

	//--INIT--/
	const initResettampilan = () => {
		setCaptionObj({}); setDatamainArr([]);// setJmlData(0);
		setFilterObj({}); setSortedObj({});
	}
	const initJmldata = () => {
		uElJmlData 
			&& (uElJmlData.innerHTML = uFilterObj.jenis==="BULAN" 
				? "&#8734;" : UFunc.formatAngka(uJmlData));
	}
	//--END INIT--/

	//--CONTENT--/
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)//>

		const vJmlTahunlaporan	= parseInt(uSystemsettingObj.jml_tahun_laporan)||5;
		const vCabangID 		= uFormdataObj.cabang_id || 0;
		const vCabangviewArr	= uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
			: uDatacabangArr.filter(vItems=>(uTokenObj.user_cabangarr || []).includes(vItems.id.toString()))
		const vTahunnow	= (new Date()).getFullYear();

		return (
		<CForm>
		{uDatacabangArr.length > 1 && (
		<CRow className="my-2">
		<CCol xs="4">Cabang</CCol>
		<CCol className="text-end">
		<CFormSelect size="sm"
			value={uFormdataObj.cabang_id||0}
			onChange={(e)=>{uFormdataObj.cabang_id = e.target.value; setEfekview(!uEfekview)}}
			id="inpcabangid">
			{vCabangviewArr.map((vItems,vKeys)=>{
				const {
					id,cabang_nama
				} = vItems

				return (
				<option value={id} key={vKeys}>{(cabang_nama||"UNDF").toUpperCase()}</option>
				)
			})}
			</CFormSelect>
		</CCol>
		</CRow>
		)}

		<CRow className="my-2">
		<CCol xs="4">Periode<span className="d-none d-lg-inline ms-1">Laporan</span></CCol>
		<CCol className="text-end">
		<CFormSelect size="sm"
			value={uFormdataObj.jenis}
			onChange={(e)=>{uFormdataObj.jenis = e.target.value; setEfekview(!uEfekview)}}
			id="inpjenis">
			<option value="BULAN">per BULAN</option>
			<option value="TAHUN">per TAHUN</option>
			</CFormSelect>
		</CCol>
		</CRow>

		{(uFormdataObj.jenis||"BULAN")==="BULAN" ? (
		<CRow className="my-2">
		<CCol xs="4">Bulan</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				style={{width:"auto"}}
				value={uFormdataObj.bln || UFunc.leadZero((new Date()).getMonth() + 1)}
				onChange={(e)=>{uFormdataObj.bln = e.target.value; setEfekview(!uEfekview)}}
				id="inpbulanid">
				{cBulanPanjang.map((vItems,vKeys) => {
					return (
						<option value={UFunc.leadZero(vKeys+1)} key={vKeys}>
						{(vItems||"UNDF").toUpperCase()}
						</option>
					)					
				})}
			</CFormSelect>
			&nbsp;
			<CFormSelect size="sm"
				value={uFormdataObj.thn || UFunc.leadZero((new Date()).getFullYear())}
				onChange={(e)=>{uFormdataObj.thn = e.target.value; setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpbulantahun">
				{(() => {
					let vTmpArr 	= [];
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		</CRow>
		) : (
		<CRow className="my-2">
		<CCol xs="4">Tahun</CCol>
		<CCol xs="8" lg="4" className="text-end">
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				value={uFormdataObj.thn || UFunc.leadZero((new Date()).getFullYear())}
				onChange={(e)=>{uFormdataObj.thn = e.target.value; setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inptahun">
				{(() => {
					let vTmpArr 	= [];
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		<CCol md="3"/>
		</CRow>
		)}
		</CForm>
		)//>
	}
	const contentLaporan = () => {
		if(!uIsDataloaded) return ""

		if(uJmlData <= 0) return (
			<div className="mx-md-5 px-lg-5">
			&middot;&middot; {uBahasaObj.caption_dataempty||"Data Tidak Ada"} &middot;&middot;
			</div>
		)//>

		//console.log("(Laplabarugi - contentLaporan) uFilterObj.jenis => "+uFilterObj.jenis);
		if(uFilterObj.jenis === "TAHUN") return contentPerTahun();
		if(uFilterObj.jenis === "BULAN") return contentPerBulan();

		return (<></>)//>
	}
	const contentPerBulan = () => {
		if(uFilterObj.jenis !== "BULAN") return ""

		const vPendapatanArr= uDatamainArr.filter(vItems => (vItems.akun_jenis||"")==="PENDAPATAN");
		const vBiayaArr		= uDatamainArr.filter(vItems => (vItems.akun_jenis||"")!=="PENDAPATAN");

		const vStyleheaderObj	= {
			fontWeight: "bolder",
			textTransform: "uppercase",
			color:"#0000FF",
		}
		const vStylejumlahObj	= {
			fontWeight: "bolder",
			textTransform: "uppercase",
			backgroundColor:"#0000FF11",
		}
		const vBorderjmlObj = { borderTop:"1px dotted #0000FF" }

		return (
		<div className="mx-lg-5">
		<table className="table table-borderless" cellPadding={5} id="idtabeldata">
		<tbody>
		<tr>
		<td colSpan={3} className="align-top text-start" style={vStyleheaderObj}>{"Pendapatan"}</td>
		</tr>
		{vPendapatanArr.map((vItems,vKeys) => {
			const {
				akun_caption,nilai
			} = vItems;

			return (
			<tr className="border-top" style={{backgroundColor:"#f0f0f055"}} key={vKeys}>
			<td colspan className="align-top text-start">{akun_caption||"UNDF"}</td>
			<td className="align-top text-end text-success">{UFunc.formatAngka(nilai)}</td>
			<td className="align-top text-end">&nbsp;</td>
			</tr>
			)
		})}
		<tr style={vStylejumlahObj}>
		<td className="text-end">JUMLAH PENDAPATAN</td>
		<td width="25%" className="align-top text-end" style={vBorderjmlObj}>&nbsp;</td>
		<td width="25%" className="text-end" style={vBorderjmlObj}><>{UFunc.formatAngka(uDatasumArr.pendapatan)}</></td>
		</tr>

		<tr>
		<td colSpan={3} className="align-top text-start" style={vStyleheaderObj}>{"Biaya"}</td>
		</tr>
		{vBiayaArr.map((vItems,vKeys) => {
			const {
				akun_caption,nilai
			} = vItems;

			return (
			<tr className="border-top" style={{backgroundColor:"#f0f0f055"}} key={vKeys}>
			<td colspan className="align-top text-start">{akun_caption||"UNDF"}</td>
			<td className="align-top text-end text-success">{UFunc.formatAngka(nilai)}</td>
			<td className="align-top text-end">&nbsp;</td>
			</tr>
			)
		})}
		<tr style={vStylejumlahObj}>
		<td className="text-end">JUMLAH Biaya</td>
		<td className="align-top text-end" style={vBorderjmlObj}>&nbsp;</td>
		<td className="text-end" style={vBorderjmlObj}><>{UFunc.formatAngka(uDatasumArr.biaya)}</></td>
		</tr>
		</tbody>
		<tfoot className="fw-bolder bg-primary text-white">
		<tr>
		<td className="text-end bg-primary">
		<big className={uDatasumArr.labarugi >= 0 ? "" : "text-warning"}>{uDatasumArr.labarugi >= 0 ? "LABA" : "RUGI"}</big>
		</td>
		<td className="align-top text-end">&nbsp;</td>
		<td className="text-end" style={{borderTop:"6px double #ffffff"}}>
		{uDatasumArr.labarugi >= 0 ? (
			<>{UFunc.formatAngka(Math.abs(uDatasumArr.labarugi))}</>
		) : (
			<span className="text-warning">({UFunc.formatAngka(Math.abs(uDatasumArr.labarugi))})</span>
		)}
		</td>
		</tr>
		</tfoot>
		</table>
		</div>
		)//>
	}
	const contentPerTahun = () => {
		if(uFilterObj.jenis !== "TAHUN") return ""

		let vNumber			= 0;
		const vDataviewArr	= uDatamainArr;

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="4" lg="5" className="text-start">{uBahasaObj.word_bulan||"Bulan"}</CCol>
			<CCol md="2" className="">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_omzet" ? " text-danger" : "")}>
				{uBahasaObj.word_omzet||"Omzet"} (Rp)
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_omzet")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_omzet" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</CCol>
			<CCol md="3" lg="2" className="">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_biaya" ? " text-danger" : "")}>
				{uBahasaObj.word_biaya||"Biaya"} (Rp)
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_biaya")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_biaya" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</CCol>
			<CCol md="3" className="">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai" ? " text-danger" : "")}>
				{uBahasaObj.caption_labarugi||"Laba/Rugi"} (Rp)
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</CCol>
			</CRow>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				bulan_caption,nilai_omzet,nilai_biaya,nilai
			} = vItems;

			vNumber++;
			return (
			<tr id={"idtr"+vKeys} className={(uRowselect===vKeys?"classrowselect":"")}
				 key={vKeys}>
			<td className="px-1 align-top text-end">{(vNumber)+"."}</td>

			<td className="px-2 px-md-1 align-top text-end">
			<CRow className="mx-0">
				<CCol xs="12" md="4" lg="5" className="text-start px-1">
					<b>{(bulan_caption||"UNDF").toUpperCase()}</b>
				</CCol>
			
				<CCol xs="12" className="my-3 d-md-none px-1 classborderbottom"/>
			
				<CCol xs="4" className="text-start d-md-none px-1">{uBahasaObj.word_omzet||"Omzet"} (Rp)</CCol>
				<CCol xs="8" md="2" className="text-end px-1 text-success">{UFunc.formatAngka(nilai_omzet)}</CCol>

				<CCol xs="4" className="text-start d-md-none px-1">{uBahasaObj.word_biaya||"Biaya"} (Rp)</CCol>
				<CCol xs="8" md="3" lg="2" className="text-end px-1 text-danger">{UFunc.formatAngka(nilai_biaya)}</CCol>
				
				<CCol xs="4" className="text-start d-md-none px-1">Laba/Rugi (Rp)</CCol>
				<CCol xs="8" md="3" className={"text-end px-1"+(parseFloat(nilai)<0 ? " text-danger":"")}>
					<span className={parseFloat(nilai)<0 ? "" : "d-none"}>(</span>
					{UFunc.formatAngka(Math.abs(parseFloat(nilai)))}
					<span className={parseFloat(nilai)<0 ? "" : "d-none"}>)</span>
				</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		<tfoot className="fw-bolder bg-primary text-white">
		<tr>
		<td className="px-1">&nbsp;</td>
		<td className="px-1">
			<CRow className={"mx-0 text-uppercase"+(parseFloat(uDatasumArr.labarugi)<0 ? " text-warning":"")}>
			<CCol xs="4" lg="5" className="text-start">{uBahasaObj.word_jumlah||"JUMLAH"}</CCol>
			<CCol md="2" className="text-end px-1 d-none d-md-block">{UFunc.formatAngka(uDatasumArr.pendapatan)}</CCol>
			<CCol md="3" lg="2" className="text-end px-0 d-none d-md-block">{UFunc.formatAngka(uDatasumArr.biaya)}</CCol>
			<CCol xs="8" md="3" className="text-end px-1">
				<span className={parseFloat(uDatasumArr.labarugi)<0 ? "" : "d-none"}>(</span>
					{UFunc.formatAngka(Math.abs(parseFloat(uDatasumArr.labarugi)))}
				<span className={parseFloat(uDatasumArr.labarugi)<0 ? "" : "d-none"}>)</span>
			</CCol>
			</CRow>
		</td>
		</tr>
		</tfoot>
		</table>
		)//>
	}
	const contentCetak = () => {
		if(uDatamainArr.length <= 0) return "";
		//console.log("(Laplabarugi - contentCetak) vHTMLs => "+vHTMLs)

		if(uFilterObj.jenis==="TAHUN") return contentCetaktahun();

		if(uFilterObj.jenis==="BULAN") return contentCetakbulan();

		return (<></>)//->
	}
	const contentCetakbulan = () => {
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		const vPendapatanArr= uDatamainArr.filter(vItems => (vItems.akun_jenis||"")==="PENDAPATAN");
		const vBiayaArr		= uDatamainArr.filter(vItems => (vItems.akun_jenis||"")!=="PENDAPATAN");

		let vHTMLs 	= uHTMLstylelaporan;
		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="3">
			<div><b>`+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"Laporan").toUpperCase()+`</b></div>
			<div>`+uCaptionObj.title+`</div>`+
			(uDatacabangArr.length > 1 
				? `<div>Cabang: `+vCabangtext+`</div>`
				: ``)+`
			</tr>
			</thead>
			<tbody>
			<tr>
			<td colspan="3" align="left"><b>PENDAPATAN</b></td>
			</tr>
		`;
		vHTMLs += vPendapatanArr.map((vItems,vKeys) => {
			const {
				akun_caption,nilai
			} = vItems;

			return (`
			<tr key={vKeys}>
			<td align="left" style="padding-left:5mm">`+(akun_caption||"UNDF")+`</td>
			<td align="right">`+UFunc.formatAngka(nilai)+`</td>
			<td align="right">&nbsp;</td>
			</tr>
			`);
		}).join("");
		vHTMLs += `
			<tr>
			<td align="right">Jumlah PENDAPATAN</td>
			<td width="25%" align="right" style="border-top:1px dashed black">&nbsp;</td>
			<td width="25%" align="right" style="border-top:1px dashed black">
				<>`+UFunc.formatAngka(uDatasumArr.pendapatan)+`</>
			</td>
			</tr>
			<tr>
			<td colspan="3" align="left"><b>BIAYA</b></td>
			</tr>
		`;
		vHTMLs += vBiayaArr.map((vItems,vKeys) => {
			const {
				akun_caption,nilai
			} = vItems;

			return (`
			<tr key={vKeys}>
			<td align="left" style="padding-left:5mm">`+(akun_caption||"UNDF")+`</td>
			<td align="right">`+UFunc.formatAngka(nilai)+`</td>
			<td align="right">&nbsp;</td>
			</tr>
			`);
		}).join("");
		vHTMLs += `
			<tr>
			<td align="right">Jumlah BIAYA</td>
			<td align="right" style="border-top:1px dotted black">&nbsp;</td>
			<td align="right" style="border-top:1px dotted black">
				<>`+UFunc.formatAngka(uDatasumArr.biaya)+`</>
			</td>
			</tr>
			</tbody>
			<tfoot>
			<tr>
			<td align="right">
				<big>`+
				(parseFloat(uDatasumArr.labarugi) >= 0 ? "LABA" : "RUGI")+
				`</big>
			</td>
			<td align="right">&nbsp;</td>
			<td align="right" style="border-top:3px double black">
				<>`+
				(parseFloat(uDatasumArr.labarugi) >= 0 
					? UFunc.formatAngka(uDatasumArr.labarugi) 
					: "("+UFunc.formatAngka(Math.abs(uDatasumArr.labarugi))+")")+
				`</>
			</td>
			</tr>
			</tfoot>
			</table>
		`;

		return vHTMLs;
	}
	const contentCetaktahun = () => {
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		let vHTMLs 	= uHTMLstylelaporan;
		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="5">
			<div><b>`+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"Laporan").toUpperCase()+`</b></div>
			<div>`+uCaptionObj.title+`</div>`+
			(uDatacabangArr.length > 1 
				? `<div>Cabang: `+vCabangtext+`</div>`
				: ``)+`
			</td>
			</tr>
			<tr class="idtabelheader">
				<th width="5%">No</th>
				<th align="left">`+(uBahasaObj.word_bulan||"Keterangan")+`</th>
				<th width="20%">`+(uBahasaObj.word_omzet||"Omzet")+` (Rp)</th>
				<th width="20%">`+(uBahasaObj.word_biaya||"Biaya")+` (Rp)</th>
				<th width="20%">`+(uBahasaObj.word_nilai)+` (Rp)</th>
			</tr>
			</thead>
			<tbody>`;
		uDatamainArr.forEach((vItems,vKeys)=>{
			const {
				bulan_caption,nilai_omzet,nilai_biaya,nilai,
			} = vItems;

			vHTMLs 	+= `
			<tr key={vKeys} valign="top">
				<td align="right" style="font-size:smaller">`+(vKeys+1)+`.</td>
				<td align="left">`+(bulan_caption||"UNDF")+`</td>
				<td align="right">`+UFunc.formatAngka(nilai_omzet)+`</td>
				<td align="right">`+UFunc.formatAngka(nilai_biaya)+`</td>
				<td align="right">`+
				(parseFloat(nilai) >= 0 ? UFunc.formatAngka(nilai)
					: "("+UFunc.formatAngka(Math.abs(nilai))+")"
					)+
				`</td>
			</tr>
			`;
		});
		vHTMLs += `
			</tbody>
			<tfoot>
			<tr>
			<td colspan="4" align="center">`+
				(parseFloat(uDatasumArr.labarugi) >= 0 ? "LABA" : "RUGI")+
			`</td>
			<td align="right">`+
				(parseFloat(uDatasumArr.labarugi) >= 0 
					? UFunc.formatAngka(uDatasumArr.labarugi) 
					: "("+UFunc.formatAngka(Math.abs(uDatasumArr.labarugi))+")")+
			`</td>
			</tr>
			</tfoot>
			</table>
		`;

		return vHTMLs;
	}
	//->
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = () => {
		uElBtnsimpan && (uElBtnsimpan.disabled = true)
		setLoading(true);
		const vTmpFilterObj 		= uFormdataObj;
		vTmpFilterObj.init_change 	= undefined;

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			clearTimeout(vTimeout)
			setLoading(false);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vApiurl	= vTmpFilterObj.jenis==="TAHUN" ? "ld_tahun" : "ld_bulan";
		const vDATAS	= JSON.stringify({
			send_filterlist : JSON.stringify(vTmpFilterObj),
			send_firstload	: uFirstload,//vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_laplr/"+vApiurl;

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			if(output_string.tampil) {
				setFilterObj(vTmpFilterObj);
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]");
				/*//--LOAD_PER_PAGE--/
				vLoadArr.map(vItems=>vItems.pg = parseInt(_PAGE)||1);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				setJmlData(parseInt(output_string.totaldata||uJmlData));
				//--END LOAD_PER_PAGE--*/

				//--LOAD_ALL_VIEW_PER_PAGE--/
				setDatamainArr(vLoadArr);
				//--END LOAD_ALL_VIEW_PER_PAGE--*/

				setFirstload("TIDAK");
				
				hdlToggleDlgform();
				setDataloaded(true);
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Laplabarugi - apiLoaddata) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Laplabarugi - apiLoaddata) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		
		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDlgformshow(false);
			setDatamainArr([]);
			setDataloaded(false)
		}
	},[])
	React.useEffect(()=>{ initJmldata() },[uElJmlData])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData])
	React.useEffect(()=>{ 
		if(uDatamainArr.length <= 0)
			{ setDatadefaultArr([]); return }
		setDatadefaultArr([{init_change:"1"},...uDatamainArr]) 
	},[uDatamainArr])
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length)
		if(uDatamainArr.length <= 0)
			{ setDatasumObj({}); return }

		let vJmlpendapatan	= 0;
		let vJmlbiaya		= 0;
		if(uFilterObj.jenis === "BULAN") {
			const vPendapatanArr= uDatamainArr.filter(vItems => (vItems.akun_jenis||"")==="PENDAPATAN");
			const vBiayaArr		= uDatamainArr.filter(vItems => (vItems.akun_jenis||"")!=="PENDAPATAN");

			vJmlpendapatan= vPendapatanArr.reduce(
				(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai||"0"), 0);
			vJmlbiaya		= vBiayaArr.reduce(
				(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai||"0"), 0);
		}
		if(uFilterObj.jenis === "TAHUN") {
			vJmlpendapatan= uDatamainArr.reduce(
				(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_omzet||"0"), 0);
			vJmlbiaya= uDatamainArr.reduce(
				(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_biaya||"0"), 0);
		}
		setDatasumObj({
			pendapatan: vJmlpendapatan,
			biaya: vJmlbiaya,
			labarugi: (vJmlpendapatan - vJmlbiaya),
		});
	},[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		/*//--LOAD_PER_PAGE--/
		if(uFirstload==="YA") return;

		setIDselect(0);
		setRowselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
		//--END LOAD_PER_PAGE--*/

		//--LOAD_ALL_VIEW_PER_PAGE--/
		setIDselect(0);
		setRowselect(-1);
		//--END LOAD_ALL_VIEW_PER_PAGE--/
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setHeaderDlgform("Pilih Filter Laporan")
		setDlgformshow(true)
		setFormdataObj({...uFilterObj, init_change: 1});
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderreset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setDataloaded(false);
	},[uHandelReset])
	React.useEffect(()=>{
		if(!uIsDataloaded)
			{ initResettampilan(); return; }

		//--SET_TITLE--/
		let vTitelmain	= "Periode ";
		if(uFormdataObj.jenis === "BULAN") {
			vTitelmain += "BULAN <b>"+cBulanPanjang[parseInt(uFormdataObj.bln)-1]+" "+uFormdataObj.thn+"</b>";
		} else {
			vTitelmain += "Tahun <b>"+uFormdataObj.thn+"</b>";
		}

		const vCabangIdx= UFunc.getObjectIndeks(uDatacabangArr,uFormdataObj.cabang_id);
		let vTitelsub	= uDatacabangArr[vCabangIdx].cabang_nama;
		//vTitelsub = "&middot; "+vTitelsub+" &middot;";
		setCaptionObj({title:vTitelmain,title_sub:vTitelsub});
		//--END SET_TITLE--/
	},[uIsDataloaded])
	React.useEffect(()=>{
		//console.log("(Laplabarugi - [uIsDlgformshow]) uFilterObj => "+JSON.stringify(uFilterObj));
		if(!uIsDlgformshow) 
			{ setFormdataObj({}); return }

		//console.log("(Laplabarugi - [uIsDlgformshow]) uFormdataObj => "+JSON.stringify(uFormdataObj));
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			setElBtnsimpan(document.getElementById("btnDialogSimpan"));
			const vElfocus = document.getElementById("inpcabangid");
			vElfocus && vElfocus.focus();
		},350)
	},[uIsDlgformshow])
	React.useEffect(()=>{
		if(uFormdataObj.cabang_id === undefined)
			uFormdataObj.cabang_id = localStorage.getItem("lastcabangid") 
				|| (uDatacabangArr[0].id || "0");
		if(uFormdataObj.jenis === undefined)
			uFormdataObj.jenis = "BULAN";

		const vTanggalNow	= new Date();
		if(uFormdataObj.bln === undefined)
			uFormdataObj.bln = UFunc.leadZero(vTanggalNow.getMonth()+1);
		if(uFormdataObj.thn === undefined)
			uFormdataObj.thn = UFunc.leadZero(vTanggalNow.getFullYear());
	},[uFormdataObj,
	uFormdataObj.cabang_id,
	uFormdataObj.jenis,
	uFormdataObj.bln,
	uFormdataObj.thn,
	])
	React.useEffect(()=>{
		if(uDatamainArr.length <= 0) return;
		if(uPageActive > 1) setPageActive(1);
		else 
			{ setRowselect(-1); setIDselect(0); }

		if(JSON.stringify(uSortedObj||{}) === "{}") { return; }

		let vSortedArr;
		const vNamasort = (uSortedObj.nama||"").toLowerCase();
		if(uSortedObj.jenis === "ASCENDING") {
			vSortedArr	= uDatamainArr.sort((a, b) => a[vNamasort] - b[vNamasort]);
		}else if(uSortedObj.jenis === "DESCENDING") {
			vSortedArr	= uDatamainArr.sort((a, b) => b[vNamasort] - a[vNamasort]);
		} else {
			vSortedArr	= uDatadefaultArr.slice(1);//.splice(0,1);
		}
		setDatamainArr(vSortedArr);
		setEfekview(!uEfekview);
	},[uSortedObj,
	uSortedObj.nama,
	uSortedObj.jenis,
	])
	
	//console.log("(Laplabarugi) uActiveroute => "+(uActiveroute));
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return; }
	if(uHtml500msg) return (
		<CCard>
		<CCardHeader className="fw-bolder">Page 500</CCardHeader>
		<CCardBody className="mx-md-5 p-md-5">
		<div className="fst-italic">
		{UFunc.renderHTML(uHtml500msg)}
		</div>
		</CCardBody>
		</CCard>
	)

	return (
		<>
		{(!uIsDataloaded) ? (
		<CCard className="classbgcard">
			<CCardHeader className="fw-bolder">Pilih Filter Laporan</CCardHeader>
			<CCardBody className="mx-lg-5 p-lg-5">
			<div className="mx-md-5 p-md-5 fst-italic classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classbgcard">
			<CCardHeader className="d-flex justify-content-between">
			<div>
				<div>{UFunc.renderHTML(uCaptionObj.title)}</div>
				{uDatacabangArr.length > 1 && (
				<span className="classfontsmaller text-primary">
					<small>{UFunc.renderHTML(uCaptionObj.title_sub)}</small>
				</span>
				)}
			</div>

			<div>
			<span className="d-none d-md-inline">
				<CTooltip content="--CETAK..">
				<CLink 
					className="classikon classikoncetak classcursorpointer"
					onClick={()=>hdlKlikCetak()}/>
				</CTooltip>

				<CTooltip content="--Export-2-Excel..">
				<CLink 
					className="classikon classikonexcel classcursorpointer"
					onClick={()=>hdlKlikExcel()}/>
				</CTooltip>
			</span>

			<CDropdown className="d-md-none">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" color="transparent">
					<CIcon icon="cilChevronBottom" className="classikonbox mx-1" height={25}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikCetak()}>
						<CIcon className="classikoncetak ms-0 me-2 align-middle" height={25}/>
						Cetak Laporan
					</CDropdownItem>
					<CDropdownItem onClick={()=>hdlKlikCetak()}>
						<CIcon className="classikonexcel ms-0 me-2 align-middle" height={25}/>
						Export-2-Excel
					</CDropdownItem>
				</CDropdownMenu>
			</CDropdown>

			<span className="mx-1">&middot;</span>

			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeelapse)} ms</small>
			</span>
			</div>
			</CCardHeader>
			
			<CCardBody className="px-0">{contentLaporan()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
			</CCardFooter>
			)}
		</CCard>
		)}
		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uHeaderDlgform}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		<div className="d-none">
			{(uSystemsettingObj.url_image_nota||"")!=="" && (
				<img src={uSystemsettingObj.url_image_nota} height="100"/>
			)}
		</div>
		</>
	)//>
}	

export default Laplabarugi